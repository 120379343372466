<template>
<div>
    <CRow>
      <CCol sm="12">
        <div>
          <CRow>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ລະຫັດ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ວັນທີ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຊື່ ແລະ ທີ່ຢູ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຕິດຕໍ່</strong>
                            </th>
                            <th class="text-center">
                              <strong>ປະເພດ <br />ລຸກຄ້າ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in cusData" :key="row.cus_id">
                            <td class="text-center">{{row.cus_id}}</td>
                            <td>{{row.cus_date}}</td>
                            <td>{{row.cus_full_name}} <br />ທີ່ຢູ່ :  {{row.cus_address}}</td>
                            <td>{{row.cus_tel}} <br /> {{row.cus_email}} </td>
                            <td class="text-right">{{row.cus_group}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
    name: "CustomerTotal",
    props: {
    cusData: {
      type: Array,
      required: true,
    },
  }
}
</script>