<template>
  <div>
    <CRow v-if="userGroup == 'administrator' || userGroup == 'manage' ">
      <CCol sm="6" lg="3">
        <CWidgetDropdown color="success" :header="countCus" text="ລູກຄ້າທັງໝົດ">
          <template #default>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CIcon name="cil-people" />
              </template>
              <CDropdownItem @click="customView">ລາຍງານ</CDropdownItem>
            </CDropdown>
          </template>
          <template #footer>
            <div class="my-3"></div>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown color="info" :header="countCusUse" text="ໃຊ້ບໍລິການທັງໝົດ">
          <template #default>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CIcon name="cil-speedometer" />
              </template>
              <CDropdownItem @click="serviceView">ລາຍງານ</CDropdownItem>
            </CDropdown>
          </template>
          <template #footer>
            <div class="my-3"></div>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown color="warning" :header="countCusInv" text="ໃກ້ຮອດວັນຮຽກເກັບ">
          <template #default>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CIcon name="cil-basket" />
              </template>
              <CDropdownItem @click="nearInvoiceView">ລາຍງານ</CDropdownItem>
            </CDropdown>
          </template>
          <template #footer>
            <div class="my-3"></div>
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown color="danger" :header="countCusCt" text="ໝົດສັນຍາບໍລິການ">
          <template #default>
            <CDropdown color="transparent p-0" placement="bottom-end">
              <template #toggler-content>
                <CIcon name="cil-file" />
              </template>
              <CDropdownItem @click="notContractView">ລາຍງານ</CDropdownItem>
            </CDropdown>
          </template>
          <template #footer>
            <div class="my-3"></div>
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="4">
        <CWidgetIcon :header="countActivity.plan" text="ວາງແຜນງານ" color="info">
          <CIcon name="cil-notes" width="24" />
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon :header="countActivity.pending" text="ກໍາລັງດໍາເນີນການ" color="warning">
          <CIcon name="cil-notes" width="24" />
        </CWidgetIcon>
      </CCol>
      <CCol sm="4">
        <CWidgetIcon :header="countActivity.success" text="ສໍາເລັດ" color="success">
          <CIcon name="cil-notes" width="24" />
        </CWidgetIcon>
      </CCol>
    </CRow>
    <CModal title="ລູກຄ້າທັງຫມົດ" size="lg" :show.sync="cusMoal" :closeOnBackdrop="false">
      <customerTotal :cusData="cusData" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal title="ລູກຄ້າໃຊ້ບໍລິການທັງໝົດ" size="lg" :show.sync="svModal" :closeOnBackdrop="false">
      <cusServiceTotal :svData="svData" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal title="ໃກ້ຮອດວັນຮຽກເກັບໃນ 60 ວັນຂ້າງໜ້າ" size="lg" :show.sync="ivModal" :closeOnBackdrop="false">
      <cusServiceNearExp :ivData="ivData" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <CModal title="ໝົດສັນຍາບໍລິການ" size="lg" :show.sync="notCnModal" :closeOnBackdrop="false">
      <customerNotContract :notData="notData" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { getCustomerCountInfoAPI } from "@/api/customer";
import { getCountActivityAPI } from "@/api/activity";
import { getCustomerAPI, getCusServiceTotalAPI, getCusSvNearInv , getCustomerNotCtAPI } from "@/api/customer";
import customerTotal from "./CustomerTotal";
import CusServiceTotal from "./CusServiceTotal";
import CusServiceNearExp from "./CusServiceNearExp";
import CustomerNotContract from "./CustomerNotContract";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Widgets",
  components: {
    customerTotal,
    CusServiceTotal,
    CusServiceNearExp,
    CustomerNotContract
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      countCus: "",
      countCusUse: "",
      countCusInv: "",
      countCusCt: "",
      countActivity: {},
      // Modal show
      cusMoal: false,
      svModal: false,
      ivModal: false,
      notCnModal: false,
      cusData: [],
      svData: [],
      ivData: [],
      notData: [],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    initialGetData() {
      getCustomerCountInfoAPI().then((response) => {
        this.countCus = response.data.data.countCus;
        this.countCusUse = response.data.data.countCusUse;
        this.countCusInv = response.data.data.countCusInv;
        this.countCusCt = response.data.data.countCusCt;
      });
      getCountActivityAPI().then((response) => {
        this.countActivity = response.data.data;
      });
    },
    customView() {
      getCustomerAPI().then((response) => {
        this.cusData = response.data.data;
        this.cusMoal = true;
      });
    },
    serviceView() {
      getCusServiceTotalAPI().then((response) => {
        this.svData = response.data.data;
        this.svModal = true;
      });
    },
    nearInvoiceView() {
      getCusSvNearInv().then((response) => {
        this.ivData = response.data.data;
        this.ivModal = true;
      });
    },
    notContractView() {
      getCustomerNotCtAPI().then((response) => {
        this.notData = response.data.data;
        this.notCnModal = true;
      });
    },
  },
};
</script>