<template>
<div>
    <CRow>
      <CCol sm="12">
        <div>
          <CRow>
            <CCol sm="12">
              <br />
            </CCol>
            <CCol sm="12">
              <CRow>
                <CCol sm="1"></CCol>
                <CCol>
                  <CRow>
                    <CCol sm="12" class="position-relative table-responsive">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນບໍລິການ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນລູກຄ້າ</strong>
                            </th>
                            <th class="text-center">
                              <strong>ຂໍ້ມູນການສໍາລະ</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="row in ivData" :key="row.service_id">
                            <td>ຊື່ບໍລິການ : {{row.service_name}} <br />ລາຄາ : {{row.service_price | numFormat(',000.00')}} / {{row.service_unit}}</td>
                            <td>ລະຫັດລຸກຄ້າ : {{row.cus_id}} <br />ຊື່ : {{row.cus_full_name}} <br />ທີ່ຢູ່ : {{row.cus_address}}</td>
                            <td>ເລກທີບິນຮັບເງິນ : {{row.inv_no}} <br />ວັນທີຈ່າຍ :  {{row.date_start}} <br />ຈ່າຍຮອດວັນທີ :  {{row.date_end}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol sm="1"></CCol>
              </CRow>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
    name: "CusServiceTotal",
    props: {
    ivData: {
      type: Array,
      required: true,
    },
  }
}
</script>