<template>
  <div>
    <CRow>
      <CCol sm="12">
        <widgets />
      </CCol>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>ປະຕິທິນແຜນງານ</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <full-calendar
                  ref="calendar"
                  :events="events"
                  :config="config"
                  @event-selected="handleDateClick"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal title="ລາຍລະອຽດຂອງແຜນງານ" size="lg" :show.sync="modalcalendar" :closeOnBackdrop="true">
      <div>
        <CRow>
          <CCol sm="12">
            <h3>
              ຫົວຂໍ້ : {{calendar.title}}
              <CBadge
                class="float-right"
                :color="getBadge(calendar.status)"
              >{{calendar.status}} {{calendar.complete}}</CBadge>
            </h3>
          </CCol>
          <CCol sm="6">
            <h5>ວັນເວລາເລີ່ມ : {{calendar.start}}</h5>
          </CCol>
          <CCol sm="6">
            <h5>ວັນເວລາສິ້ນສຸດ : {{calendar.end}}</h5>
          </CCol>
          <CCol sm="12">
            <div>
              <hr />
              <br />
            </div>
          </CCol>
          <CCol sm="12">
            <h5>ລາຍລະອຽດ :</h5>
            <vueEditor v-model="calendar.description" :editorToolbar="customToolbar" disabled />
          </CCol>
          <CCol sm="12">
            <div>
              <hr />
              <br />
            </div>
          </CCol>
          <CCol sm="6"></CCol>
          <CCol sm="6">
            <strong>ວັນທີສ້າງ : {{calendar.createDate}}</strong>
            <br />
            <strong>ສ້າງໂດຍ :</strong>
            {{calendar.createAt}}
            <br />
            <strong>ວັນທີແກ້ໄຂຫຼ້າສຸດ : {{calendar.status_at}}</strong>
            <br />
            <strong>ແກ້ໄຂໂດຍ :</strong>
            {{this.calendar.status_by}}
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { getActivityAPI, getActivityByIdAPI } from "@/api/activity";
import widgets from "./components/Widgets";
import { FullCalendar } from "vue-full-calendar";
import { VueEditor } from "vue2-editor";
export default {
  name: "Dashboard",
  components: {
    FullCalendar,
    widgets,
    VueEditor,
  },
  data() {
    return {
      config: {
        defaultView: "month",
        locale: "lo",
        selectable: true,
        timezone: "local",
        buttonText: {
          today: "ມື້ນີ້",
          month: "ເດືອນ",
          week: "ອາທິດ",
          day: "ວັນ",
        },
      },
      events: [],
      calendar: {
        title: "",
        start: "",
        end: "",
        description: "",
        createDate: "",
        createAt: "",
      },
      modalcalendar: false,
      customToolbar: [[]],
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    getBadge(status) {
      return status === "ວາງແຜນງານ"
        ? "danger"
        : status === "ກໍາລັງດໍາເນີນການ"
        ? "warning"
        : status === "ສໍາເລັດແລ້ວ"
        ? "success"
        : "warning";
    },
    initialGetData() {
      getActivityAPI().then((response) => {
        this.events = response.data.data;
      });
    },
    handleDateClick(event) {
      this.modalcalendar = true;
      this.calendar.title = event.title;
      this.calendar.complete = event.complete;
      this.calendar.status = event.status;
      this.calendar.start = event.start;
      this.calendar.end = event.end;
      this.calendar.description = event.description;
      this.calendar.createDate = event.createDate;
      this.calendar.createAt = event.createAt;
      this.calendar.status_at = event.status_at;
      this.calendar.status_by = event.status_by;
    },
  },
};
</script>
